.Container-Row {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .Container-Col {
        position: relative;
    }
}

.Answer-Part {
    margin-top: 25px;
}